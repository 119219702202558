import { Component } from '@angular/core';
import { Slide } from 'src/app/model/slide';

@Component({
  selector: 'banner-component',
  templateUrl: './intro-banner.component.html',
  styleUrls: ['./intro-banner.component.sass']
})
export class IntroBannerComponent {
  slides: Slide[] = [
    {path: "assets/banner-slides/main_abitur.png", tag:"main_abitur"},
    {path: "assets/banner-slides/sport.jpg", tag:"main_abitur"},
    {path: "assets/banner-slides/studs.jpg", tag:"main_abitur"},
    {path: "assets/banner-slides/studs1.jpg", tag:"main_abitur"},
    {path: "assets/banner-slides/vibori.jpg", tag:"main_abitur"},
  ]

  ngOnInit() {
  }
}
