<div class="college-wrapper">
  <div class="intro">
    <h2>{{'main-page.college.name' | translate}}</h2>
  </div>
  <div class="cont">
    <div class="content">
      <div class="text-imgs">
        <div class="big-text" [innerHTML]="'main-page.college.big-text'| translate"></div>
        <div class="images">
          <a href="http://xn----7sbgfh2alwzdhpc0c.xn--90ais/organization/38327/org-page" target="_blank"><img src="assets/banner-slides/qrkod.png" alt="" class="img-fluid"></a>
          <a href="https://www.belta.by/president/view/v-belarusi-uchrezhden-gosudarstvennyj-znak-kachestva-610589-2024/" target="_blank"><img src="assets/banner-slides/znak_kachestva.png" alt="" class="img-fluid"></a>
        </div>
       



      </div>
      <div class="custom-card">
        <div class="card-thumb">
          <a href="https://president.gov.by/ru/gosudarstvo/simvolika" target="_blank"><img src="assets/banner-slides/gerb-flag.png" alt="" class="img-fluid"></a>
          <a href="about-college/godkachestva" target="_blank"><img src="assets/banner-slides/god_kachestva.png" alt="" class="img-fluid"></a>
        </div>
      </div>

    </div>
    <div class="images8">
      <a href="https://www.rec.gov.by/ru" target="_blank"><img src="assets/banner-slides/vibori.jpg" alt="" class="img-fluid"></a>
    </div>
  </div>
</div>
