<div class="cont">
    <H2 class="head">{{'documentation.head'|translate}}</H2>
<docs-table [translations]="translations"></docs-table>
<div class="table">
    <p class="head1" [innerHTML]="'documentation.text' | translate"></p>
    <table>
        <tbody>
            <tr>
                <td width="20%">КОЙДА Мария Ивановна</td>
                <td>Заведующий общежитием</td>
                <td width="15%">353 58 21</td>
                <td>Общежитие (ул. Ваупшасова, 23/1)</td>
                <td>ЖИЛИЩНО-КОМУНАЛЬНОЕ ХОЗЯЙСТВО</td>
                <td width="20%">ОБЩЕЖИТИЕ</td>
            </tr>
            <tr>
                <td>ДОБРОВОЛЬСКАЯ Татьяна Владимировна</td>
                <td>Заведующий столовой</td>
                <td>374 57 02</td>
                <td>Столовая</td>
                <td>ОБЩЕСТВЕННОЕ ПИТАНИЕ</td>
                <td>СТОЛОВАЯ</td>
            </tr>
        </tbody>
    </table>
</div>
</div>
