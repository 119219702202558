<div class="cont">
    <H2 class="head">{{'pto.head'|translate}}</H2>
    
</div>

<div class="cont">

    <div class="roww">
        <img src="assets/administration/aliabeva.jpg" alt="Фотография" width="250" height="320">

    </div>
</div>

<div class="cont">
        <p class="head5" [innerHTML]="'pto.text' | translate"></p>

    <docs-table [translations]="translations"></docs-table>
</div>

<div class="contt">
    <table>
        <tbody>
            <tr>
                <td rowspan="3" >СПЕЦИАЛЬНОСТЬ</td>
                <td >3-36 01 54</td>
                <td >Механическая обработка металла на станках и линиях</td>
            </tr>
            <tr>
                <td>4-02-0714-01</td>
                <td>Обработка деталей на металлорежущих станках</td>
            </tr>
            <tr>
                <td>4-02-0714-02</td>
                <td>Эксплуатация и наладка автоматизированного <br>машиностроительного производства</td>
            </tr>
            <tr>
                <td>СРОК ПОЛУЧЕНИЯ   <br>  ОБРАЗОВАНИЯ</td>
                <td colspan="2">В дневной форме на основе общего базового образования – 2 года 10 месяцев</td>
            </tr>
            <tr>
                <td rowspan="5">КВАЛИФИКАЦИИ</td>
                <td>3-36 01 54-55</td>
                <td>Токарь 3-го разряда</td>
            </tr>
            <tr>
                <td>3-36 01 54-53</td>
                <td>Оператор станков с программным управлением 4-го разряда</td>
            </tr>
            <tr>
                <td>4-02-0714-01-03</td>
                <td>Токарь 3-го разряда</td>
            </tr>
            <tr>
                <td>4-02-0714-02-02</td>
                <td>Оператор станков с программным управлением 4-го разряда</td>
            </tr>
            <tr>
                <td>4-02-0714-01-04</td>
                <td>Фрезеровщик 3-го разряда</td>
            </tr>
        </tbody>
    </table>
</div>

<div class="contt">

    <p class="head1" [innerHTML]="'pto.text1' | translate"></p>
    <table>
        <tbody>
            <tr>
                <td rowspan="4" >СПЕЦИАЛЬНОСТЬ</td>
                <td >3-36 01 53</td>
                <td >Техническая эксплуатация оборудования</td>
            </tr>
            <tr>
                <td>3-37 01 52</td>
                <td>Эксплуатация и ремонт автомобилей</td>
            </tr>
            <tr>
                <td>4-02-0714-03</td>
                <td>Сборочные и ремонтные работы</td>
            </tr>
            <tr>
                <td>4-02-0715-01</td>
                <td>Эксплуатация, ремонт и обслуживание автомобилей</td>
            </tr>
            <tr>
                <td>СРОК ПОЛУЧЕНИЯ  <br> ОБРАЗОВАНИЯ</td>
                <td colspan="2">В дневной форме на основе  общего  базового образования – 2 года 10 месяцев</td>
            </tr>
            <tr>
                <td rowspan="4">КВАЛИФИКАЦИИ</td>
                <td>3-36 01 53-54</td>
                <td>Слесарь механосборочных работ 4-го разряда</td>
            </tr>
            <tr>
                <td>3-37 01 52-52</td>
                <td>Слесарь по ремонту автомобилей 3-го разряда</td>
            </tr>
            <tr>
                <td>4-02-0714-03-02</td>
                <td>Слесарь механосборочных работ 4-го разряда</td>
            </tr>
            <tr>
                <td>4-02-0715-01-03</td>
                <td>Слесарь по ремонту автомобилей 3-го разряда</td>
            </tr>
        </tbody>
    </table>
</div>


<div class="cont">

    <p class="head1" [innerHTML]="'pto.text2' | translate"></p>
    <table>
        <tbody>
            <tr>
                <td rowspan="2">Курс</td>
                <td rowspan="2">№
    группы</td>
                <td colspan="2">Количество учащихся</td>
                <td rowspan="2">Количество
    вакантных мест
    </td>
                <td rowspan="2">Куратор</td>
            </tr>
            <tr>
                <td>на момент поступления</td>
                <td>на 01 сентября 2024 г.</td>
            </tr>
            <tr>
                <td rowspan="3">I</td>
                <td>411</td>
                <td>28</td>
                <td>28</td>
                <td>0</td>
                <td>Хиневич Сергей Леонтьевич</td>
            </tr>
            <tr>
                <td>421</td>
                <td>28</td>
                <td>28</td>
                <td>0</td>
                <td>Охотенко Александра Леонидовна</td>
            </tr>
            <tr>
                <td>422</td>
                <td>28</td>
                <td>28</td>
                <td>0</td>
                <td>Потачиц Иван Александрович</td>
            </tr>
            <tr>
                <td rowspan="3">II</td>
                <td>311</td>
                <td>31</td>
                <td>31</td>
                <td>0</td>
                <td>Стома Роман Николаевич</td>
            </tr>
            <tr>
                <td>321</td>
                <td>30</td>
                <td>30</td>
                <td>0</td>
                <td>Шпаковский Вячеслав Францевич</td>
            </tr>
            <tr>
                <td>322</td>
                <td>30</td>
                <td>29</td>
                <td>1</td>
                <td>Винокурова Анна Сергеевна</td>
            </tr>
            <tr>
                <td rowspan="2">III</td>
                <td>211</td>
                <td>28</td>
                <td>28</td>
                <td>0</td>
                <td>Поскребко Юрий Михайлович</td>
            </tr>
            <tr>
                <td>221</td>
                <td>30</td>
                <td>28</td>
                <td>2</td>
                <td>Слоним Илья Николаевич</td>
            </tr>
            <tr>
                <td colspan="2">ИТОГО:</td>
                <td>233</td>
                <td>230</td>
                <td>3</td>
                <td></td>
            </tr>
        </tbody>
    </table>
</div>

<div class="cont">
    <div class="display-row">
        <img src="assets/components/otdelenia/Slesar.png" alt="Фотография" width="700" height="1000">
        <img src="assets/components/otdelenia/Oper.png" alt="Фотография" width="700" height="1000">
    </div>
</div>