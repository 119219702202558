<div class="cont">
    <H2 class="head">{{'life.head'|translate}}</H2>
 
<docs-table [translations]="translations"></docs-table> 
<p class="head5" [innerHTML]="'life.text' | translate"></p>
<p class="head1" [innerHTML]="'life.text2' | translate"></p>
<div class="display-row">
    <img src="assets/components/life/1.png.jpg" alt="Фотография" width="450" height="500">
    <img src="assets/components/life/2.jpg" alt="Фотография" width="450" height="500">
</div>


<p class="head1" [innerHTML]="'life.text20' | translate"></p>
<p class="head2" [innerHTML]="'life.text3' | translate"></p>

<p class="head1" [innerHTML]="'life.text4' | translate"></p>

<p class="head5" [innerHTML]="'life.text5' | translate"></p>
<div class="display-row">
    <img src="assets/components/life/3.jpg" alt="Фотография" width="450" height="300">
    <img src="assets/components/life/4.jpg" alt="Фотография" width="450" height="300">
</div>

<p class="head5" [innerHTML]="'life.text6' | translate"></p>


<p class="head1" [innerHTML]="'life.text9' | translate"></p>
<div class="display-row">
    <img src="assets/components/life/5.jpg" alt="Фотография" width="350" height="450">
    <img src="assets/components/life/6.jpg" alt="Фотография" width="450" height="300">
</div>

<p class="head1" [innerHTML]="'life.text10' | translate"></p>
<div class="display-row">
    <img src="assets/components/life/7.jpg" alt="Фотография" width="450" height="300">
    <img src="assets/components/life/8.jpg" alt="Фотография" width="450" height="300">
    <img src="assets/components/life/9.jpg" alt="Фотография" width="450" height="300">
</div>

<p class="head1" [innerHTML]="'life.text11' | translate"></p>
<div class="display-row">
    <img src="assets/components/life/10.jpg" alt="Фотография" width="450" height="300">
    <img src="assets/components/life/11.jpg" alt="Фотография" width="450" height="300">
</div>

<p class="head1" [innerHTML]="'life.text12' | translate"></p>
<div class="display-row">
    <img src="assets/components/life/12.jpg" alt="Фотография" width="400" height="500">
    <img src="assets/components/life/13.jpg" alt="Фотография" width="400" height="500">
    <img src="assets/components/life/14.jpg" alt="Фотография" width="400" height="500">
</div>

<p class="head1" [innerHTML]="'life.text13' | translate"></p>
<div class="display-row">
    <img src="assets/components/life/15.jpg" alt="Фотография" width="450" height="300">
    <img src="assets/components/life/16.jpg" alt="Фотография" width="450" height="300">
</div>

<p class="head1" [innerHTML]="'life.text14' | translate"></p>
<div class="display-row">
    <img src="assets/components/life/17.jpg" alt="Фотография" width="400" height="500">
</div>

<p class="head1" [innerHTML]="'life.text15' | translate"></p>
<div class="display-row">
    <img src="assets/components/life/18.jpg" alt="Фотография" width="450" height="300">
    <img src="assets/components/life/19.jpg" alt="Фотография" width="450" height="300">
</div>

<p class="head1" [innerHTML]="'life.text16' | translate"></p>
<div class="display-row">
    <img src="assets/components/life/20.jpg" alt="Фотография" width="450" height="300">
    <img src="assets/components/life/21.jpg" alt="Фотография" width="450" height="300">
</div>

<p class="head1" [innerHTML]="'life.text17' | translate"></p>
<div class="display-row">
    <img src="assets/components/life/22.jpg" alt="Фотография" width="450" height="300">
    <img src="assets/components/life/23.jpg" alt="Фотография" width="250" height="300">
</div>

<p class="head1" [innerHTML]="'life.text18' | translate"></p>
<div class="display-row">
    <img src="assets/components/life/24.jpg" alt="Фотография" width="450" height="300">
    <img src="assets/components/life/25.jpg" alt="Фотография" width="450" height="300">
    <img src="assets/components/life/26.jpg" alt="Фотография" width="450" height="300">
</div>

<p class="head1" [innerHTML]="'life.text19' | translate"></p>

<p class="head5" [innerHTML]="'life.text1' | translate"></p>

<video width="320" height="240" controls="controls" poster="video/duel.jpg">
    <source src="https://cloud.mail.ru/stock/8Cipj1BuUeHvCe3ErrdG9Eku/FcYjgpwT8Lx/%D0%BE%D1%82%D0%B7%D1%8B%D0%B2%D1%8B.mp4" type="video/mp4" controls="controls">
    Your browser does not support the video tag.
    <source src="https://cloud.mail.ru/stock/8Cipj1BuUeHvCe3ErrdG9Eku/FcYjgpwT8Lx/%D0%BE%D1%82%D0%B7%D1%8B%D0%B2%D1%8B.mp4" type="video/webm">
    </video>
    <a href="https://cloud.mail.ru/stock/8Cipj1BuUeHvCe3ErrdG9Eku/FcYjgpwT8Lx/%D0%BE%D1%82%D0%B7%D1%8B%D0%B2%D1%8B.mp4">Перейдите по этой ссылке, если видео не загружается</a>.
</div> 
