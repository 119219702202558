import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocsTableComponent } from "../../../docs-table/docs-table.component";
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-life',
  standalone: true,
  imports: [CommonModule, DocsTableComponent, TranslateModule],
  templateUrl: './life.component.html',
  styleUrls: ['./life.component.sass']
})
export class LifeComponent {
  translations: string = 'life';
}
